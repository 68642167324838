import React from 'react';
import {Helmet} from 'react-helmet';

class NotFoundPage extends React.Component {

    render() {
        return (
            <div className={'ui grid'}>
                <Helmet>
                    <title>HTTP 404 - Seite nicht gefunden</title>
                    <meta charSet="utf-8"/>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, minimum-scale=1, maximum-scale=1.0"
                    />
                    <meta
                        name="description"
                        content="www.aaa-soft.net - Wir setzten durch agile und innovative Entwicklung Ihre Webapplikation mit den neusten Technologien um – Ihr Mehrwert von Anfang an!"
                    />
                    <meta name="author" content="Triple-A Soft UG"/>
                    <meta name="revisit-after" content="30 days"/>
                    <meta name="expires" content="NEVER"/>
                    <meta name="publisher" content="Triple-A Soft UG"/>
                    <meta name="copyright" content="Triple-A Soft UG"/>
                    <meta name="content-language" content="de"/>
                    <meta name="language" content="deutsch, de"/>
                    <meta name="audience" content="Alle"/>
                    <meta name="robots" content="noindex,follow"/>
                </Helmet>
                <div className={'row centered'}>
                    <div
                        className={
                            'center aligned seven wide computer seven wide mobile seven wide tablet column'
                        }
                    >
                        <i
                            aria-hidden="true"
                            className="frown outline icon massive"
                            style={{color: '#F39A38'}}
                        />
                        <h2 className={'subheader-text'}>HTTP 404 - Es tut uns leid, diese Seite ist wohl nicht mehr
                            da!</h2>
                        <p className={'blue-text'}>Bitte versuchen Sie, über unsere Startseite die gewünschten
                            Informationen abzurufen. Oder nehmen Sie Kontakt zu uns auf.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFoundPage;
